<template>
<div class="bg-light px-5 py-3 font-family: 'Inter'">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6" >
                   <label @click="back()" style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 28px; color: #9E9E9E; "><span class="fas fa-arrow-left"></span> Kembali ke List Vending</label>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right ">
                        <li class="ml-3"><button type="button" class="btn btn-block btn-outline-primary btn-lg p-3"   @click="showModal"><span class="fas fa-plus"> </span>  Edit</button></li>
                    </ol>
                </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
               
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>

     <!-- /.modal -->
        <div class="modal fade" id="slotModal" tabindex="-1" role="dialog" aria-labelledby="slotModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <form @submit.prevent="save" class="card">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="slotModalLabel"> <i class="fa fa-plus"></i> Assign Product</h5>
                </div>
                <div class="modal-body">
                    <div class="row border" v-if="formSlot.product.id">
                          <div class="col-sm-4">
                            <img :src="formSlot.product.image"  width="100%" height="100%" class="img-thumbnail" alt="No Image">
                          </div>
                          <div class="col-sm-6 align-self-center ml-2">
                             <div class="row">
                                <label>{{formSlot.product.sku}}</label>
                              </div>
                              <div class="row">
                                <label>{{formSlot.product.name}}</label>
                              </div>
                          </div>
                          <div class="col-sm-1 align-self-center" v-on:click="clearProduct"> <label class="text-blue">X</label></div>
                    </div>
                    <div class="form-group" v-else >
                        <label for="recipient-price" class="col-form-label">Produk<span class="text-red">*</span></label>
                        <select2 v-model="formSlot.product.id" required :config="productConfig" class="form-control"  v-on:change="signalChange">
                            <option :value="formSlot.product.id" selected v-if="formSlot.product.id && this.$route.params.id">{{ formSlot.product.name }}</option>
                        </select2>
                    </div>
                    
                    <div class="form-group">
                        <label for="recipient-price" class="col-form-label">Harga<span class="text-red">*</span></label>
                        <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-price" v-model="formSlot.price" :disabled="loading">
                    </div>
                        <div class="form-group">
                        <label for="recipient-stock" class="col-form-label">Quantity<span class="text-red">*</span></label>
                        <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-stock" v-model="formSlot.stock" :disabled="loading">
                    </div>       
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                     <button type="submit" class="btn btn-primary" :disabled="loading">
                        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                        {{ loading ? 'Saving ...' : 'Save' }}
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <form @submit.prevent="updateVending" class="card">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="exampleModalLabel">  Edit Vending</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="recipient-name" class="col-form-label">Vending Name<span class="text-red">*</span></label>
                        <input type="text" class="form-control" id="recipient-name" v-model="formVending.name" :disabled="loading">
                    </div>
                    <div class="form-group">
                        <label for="recipient-vending-type" class="col-form-label">Vending Type<span class="text-red">*</span></label>
                        <select2 v-model="formVending.vending_type.id" required :config="vendingTypeConfig" class="form-control">
                            <option :value="formVending.vending_type.id" selected v-if="formVending.vending_type.id && this.$route.params.id">{{ formVending.vending_type.type_name }}</option>
                        </select2>
                    </div>
                    <div class="form-group">
                        <label for="recipient-name" class="col-form-label">Merchant Name<span class="text-red">*</span></label>
                        <select2 v-model="formVending.merchant.id" required :config="merchantConfig" class="form-control">
                            <option :value="formVending.merchant.id" selected v-if="formVending.merchant.id && this.$route.params.id">{{ formVending.merchant.name }}</option>
                        </select2>
                    </div>
                    <div class="form-group">
                        <label for="recipient-vending_id" class="col-form-label">Vending Id<span class="text-red">*</span></label>
                        <input type="text" class="form-control" id="recipient-vending_id" v-model="formVending.vending_id" disabled>
                    </div>
                    <div class="form-group">
                        <label for="recipient-address" class="col-form-label">Address<span class="text-red">*</span></label>
                        <input type="text" class="form-control" id="recipient-address" v-model="formVending.address" :disabled="loading">
                    </div>  
                    <div class="form-group">
                        <label for="recipient-row_slot" class="col-form-label">Baris Slot<span class="text-red">*</span></label>
                        <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-row_slot" v-model="formVending.row_slot" :disabled="loading">
                    </div>   
                    <div class="form-group">
                        <label for="recipient-col_slot" class="col-form-label">Kolom Slot<span class="text-red">*</span></label>
                        <input type="number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control" id="recipient-col_slot" v-model="formVending.col_slot" :disabled="loading">
                    </div>        
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                     <button type="submit" class="btn btn-primary" :disabled="loading">
                        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                        {{ loading ? 'Saving ...' : 'Save' }}
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>


    <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-4">
                    <div class="box1 m-2">
                        <div class="inner" v-if="vending">
                          <p class="label1" >{{vending.name}}</p>
                          <p class="label2" >{{vending.merchant.name}} </p>
                          <p class="label3" >{{vending.vending_id}}</p>
                          <p class="label3" >{{vending.address}}</p>
                        </div>
                    </div>
                </div><!-- /.col -->
                <div class="col-sm-8">
                    <ol class="breadcrumb float-sm-right bg-light ">
                        <li class="mr-4">
                            <div class="box1 m-2">
                                <div class="inner" v-if="count">
                                  <p class="label4">Jumlah Transaksi</p>
                                  <h3 >{{count.curren_count}}</h3>
                                      <p v-if="count.status_value=='naik'"> <span style="color:#3267E3" > <i class="fas fa-arrow-up"></i> {{count.percentage_count}}% </span> from last month</p>
                                      <p v-if="count.status_value=='turun'"> <span style="color:red"> <i class="fas fa-arrow-down"></i> {{count.percentage_count}}% </span> from last month</p>
                                </div>
                            </div>
                        </li>
                        <li >
                            <div class="box1 m-2">
                                <div class="inner" v-if="count">
                                  <p class="label4">Nilai Transaksi</p>
                                  <h3 >Rp {{formatPrice(count.curren_value)}}</h3>
                                      <p v-if="count.status_count=='naik'"> <span style="color:#3267E3"> <i class="fas fa-arrow-up"></i> {{count.percentage_value}}% </span> from last month</p>
                                      <p v-if="count.status_count=='turun'"> <span style="color:red"> <i class="fas fa-arrow-down"></i> {{count.percentage_value}}% </span> from last month</p>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    <div class="container-fluid">
        <div class="card ">
            <div class="row p-2">
                <div  class="col m-2 " style="text-align: center; align-items: center;" id="div1" @click="btnAddUpdate(1)">
                  <input type="text" id="id1" value="0" hidden>
                   <p class="label5" style="color: #000000;">1</p >
                  <div class="bg-light border border-light " style="width:100%;">
                    <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p >Rp 0 </p>
                      <P > 0/11</p>
                    </div>
                  </div>
                     
                </div>

                <div class="col m-2 " style="text-align: center; align-items: center;" id="div2" @click="btnAddUpdate(2)">
                     <input type="text" id="id2" value="0" hidden>
                    <p class="label5" style="color: #000000;">2</p >
                    <div class="bg-light border border-light " style="width:100%;">
                    <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div3" @click="btnAddUpdate(3)">
                      <input type="text" id="id3" value="0" hidden>
                    <p class="label5" style="color: #000000;">3</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div4" @click="btnAddUpdate(4)">
                        <input type="text" id="id4" value="0" hidden>
                      <p class="label5" style="color: #000000;">4</p >
                    <div class="bg-light border border-light " style="width:100%;"> 
                      <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div5" @click="btnAddUpdate(5)">
                        <input type="text" id="id5" value="0" hidden>
                      <p class="label5" style="color: #000000;">5</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div6" @click="btnAddUpdate(6)">
                        <input type="text" id="id6" value="0" hidden>
                      <p class="label5" style="color: #000000;">6</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div7" @click="btnAddUpdate(7)">
                        <input type="text" id="id7" value="0" hidden>
                      <p class="label5" style="color: #000000;">7</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div8" @click="btnAddUpdate(8)">
                       <input type="text" id="id8" value="0" hidden>
                        <p class="label5" style="color: #000000;">8</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>

         <div class="card ">
            <div class="row p-2">
                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div9" @click="btnAddUpdate(9)">
                        <input type="text" id="id9" value="0" hidden>
                        <p class="label5" style="color: #000000;">9</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div10" @click="btnAddUpdate(10)">
                        <input type="text" id="id10" value="0" hidden>
                      <p class="label5" style="color: #000000;">10</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div11" @click="btnAddUpdate(11)">
                        <input type="text" id="id11" value="0" hidden>
                        <p class="label5" style="color: #000000;">11</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div12" @click="btnAddUpdate(12)">
                        <input type="text" id="id12" value="0" hidden>
                        <p class="label5" style="color: #000000;">12</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div13" @click="btnAddUpdate(13)">
                        <input type="text" id="id13" value="0" hidden>
                      <p class="label5" style="color: #000000;">13</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div14" @click="btnAddUpdate(14)">
                        <input type="text" id="id14" value="0" hidden>
                        <p class="label5" style="color: #000000;">14</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div15" @click="btnAddUpdate(15)">
                    <input type="text" id="id15" value="0" hidden>
                        <p class="label5" style="color: #000000;">15</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div16" @click="btnAddUpdate(16)">
                        <input type="text" id="id16" value="0" hidden>
                        <p class="label5" style="color: #000000;">16</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>

         <div class="card ">
            <div class="row p-2">
                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div17" @click="btnAddUpdate(17)">
                      <input type="text" id="id17" value="0" hidden>
                      <p class="label5" style="color: #000000;">17</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div18" @click="btnAddUpdate(18)">
                      <input type="text" id="id18" value="0" hidden>
                        <p class="label5" style="color: #000000;">18</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div19" @click="btnAddUpdate(19)">
                      <input type="text" id="id19" value="0" hidden>
                        <p class="label5" style="color: #000000;">19</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div20" @click="btnAddUpdate(20)">
                    <input type="text" id="id20" value="0" hidden>
                    <p class="label5" style="color: #000000;">20</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div21" @click="btnAddUpdate(21)">
                    <input type="text" id="id21" value="0" hidden>
                    <p class="label5" style="color: #000000;">21</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div22" @click="btnAddUpdate(22)">
                    <input type="text" id="id22" value="0" hidden>
                    <p class="label5" style="color: #000000;">22</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div23" @click="btnAddUpdate(23)">
                    <input type="text" id="id23" value="0" hidden>
                    <p class="label5" style="color: #000000;">23</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div24" @click="btnAddUpdate(24)">
                    <input type="text" id="id24" value="0" hidden>
                    <p class="label5" style="color: #000000;">24</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>

         <div class="card ">
            <div class="row p-2">
                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div25" @click="btnAddUpdate(25)">
                    <input type="text" id="id25" value="0" hidden>
                    <p class="label5" style="color: #000000;">25</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div26" @click="btnAddUpdate(26)">
                    <input type="text" id="id26" value="0" hidden>
                    <p class="label5" style="color: #000000;">26</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div27" @click="btnAddUpdate(27)">
                    <input type="text" id="id27" value="0" hidden>
                    <p class="label5" style="color: #000000;">27</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div28" @click="btnAddUpdate(28)">
                    <input type="text" id="id28" value="0" hidden>
                    <p class="label5" style="color: #000000;">28</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div29" @click="btnAddUpdate(29)">
                    <input type="text" id="id29" value="0" hidden>
                    <p class="label5" style="color: #000000;">29</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div30" @click="btnAddUpdate(30)">
                    <input type="text" id="id30" value="0" hidden>
                    <p class="label5" style="color: #000000;">30</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div31" @click="btnAddUpdate(31)">
                    <input type="text" id="id31" value="0" hidden>
                    <p class="label5" style="color: #000000;">31</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div32" @click="btnAddUpdate(32)">
                    <input type="text" id="id32" value="0" hidden>
                    <p class="label5" style="color: #000000;">32</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                
                
            </div>
        </div>

         <div class="card ">
            <div class="row p-2">
                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div33" @click="btnAddUpdate(33)">
                    <input type="text" id="id33" value="0" hidden>
                    <p class="label5" style="color: #000000;">33</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div34" @click="btnAddUpdate(34)">
                    <input type="text" id="id34" value="0" hidden>
                    <p class="label5" style="color: #000000;">34</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div35" @click="btnAddUpdate(35)">
                    <input type="text" id="id35" value="0" hidden>
                    <p class="label5" style="color: #000000;">35</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div36" @click="btnAddUpdate(36)">
                    <input type="text" id="id36" value="0" hidden>
                    <p class="label5" style="color: #000000;">36</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>
                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div37" @click="btnAddUpdate(37)">
                    <input type="text" id="id37" value="0" hidden>
                    <p class="label5" style="color: #000000;">37</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div38" @click="btnAddUpdate(38)">
                    <input type="text" id="id38" value="0" hidden>
                    <p class="label5" style="color: #000000;">38</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div39" @click="btnAddUpdate(39)">
                    <input type="text" id="id39" value="0" hidden>
                    <p class="label5" style="color: #000000;">39</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>

                <div class="col m-2 "  style="text-align: center; align-items: center;" id="div40" @click="btnAddUpdate(40)">
                    <input type="text" id="id40" value="0" hidden>
                    <p class="label5" style="color: #000000;">40</p >
                    <div class="bg-light border border-light " style="width:100%;">
                     <img  src="@assets/img/Placeholder.jpg" style=" width: 124px; height: 150px; " />
                    <div  style="width:100%; background: #F5F5F5; bottom:0;"> 
                      <p>Rp 0 </p>
                      <P> 0/11</p>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
        
        
  
      </div>
    </div>
</template>
<script>
const {console} = window
import axios from "axios"
export default {
 
  data(){
    return{
      productConfig: {}, 
      slotList : null,
      error: undefined,
      loading: false,
      vending:null,
      count:null,
      id:null,
      vending_id:null,
      merchantConfig: {},
      formSlot:{
          id: null,
          name:"",
          product:{
            id: null,
            name:null,
            image:null,
            sku:null
          },
          vending:{
            id: null
          },
          price:0,
          stock:0
      }, 
      formVending:{
        id: null,
        vending_id:"",
        name:"",
        address:"",
        row_slot:null,
        col_slot:null,
        merchant:{
            id : null
        }
      }
    }

  },
  mounted() {
        this.getData()
        this.initData()
        this.getDataVending()
        this.getDataCount()
        this.getDataMerchant()
  },
  methods : {
    initData() {
        this.productConfig = {
            ajax: {
                url: env.api.base + '/product?sort=id',
                data: params => {
                    var filters = [
                        ["name", "like", params.term]
                    ]
                    var query = {
                        sort: "name",
                        filters: params.term ? JSON.stringify(filters) : "",
                        page: params.page ? params.page : 1
                    }

                    return query
                },
                processResults: (data, params) => {
                    data.content.forEach(item => {
                        item.text = item.name
                    })
                    return {
                        results: data.content,
                        pagination: {
                            more: data.last == false
                        }
                    }
                }
            }
        },
        this.vendingTypeConfig = {
          ajax: {
              url: env.api.base + '/vendingType?sort=id',
              data: params => {
                  var filters = [
                      ["type_name", "like", params.term]
                  ]
                  var query = {
                      sort: "type_name",
                      filters: params.term ? JSON.stringify(filters) : "",
                      page: params.page ? params.page : 1
                  }

                  return query
              },
              processResults: (data, params) => {
                  data.content.forEach(item => {
                      item.text = item.type_name
                  })
                  return {
                      results: data.content,
                      pagination: {
                          more: data.last == false
                      }
                  }
              }
          }
      }
    },

    async getData(){
       if (this.$route.params.id) {
            this.vending_id=this.$route.params.id;
            this.Api.get(`/slot?size=100&filters=[["vending.id","=","${this.$route.params.id}"]]`).then(res => {
                this.slotList=res.data.content
                for (let i = 0; i < this.slotList.length; i++) {
                   $("#div"+(this.slotList[i].name)+"").html(' <p class="label5" style="color: #000000;">'+this.slotList[i].name+'</p > <input type="text" id="id'+this.slotList[i].name+'" value="'+this.formatPrice(this.slotList[i].id)+'" hidden>'+
                    '<div class="bg-light border border-light " style="width:100%; cursor: pointer" >'+
                    '<img  src="'+this.slotList[i].product.image+'" style=" width: 124px; height: 150px; " />'+
                    ' <div  style="width:100%; background: #F5F5F5; bottom:0;"> '+
                    '<p id="">Rp '+this.formatPrice(this.slotList[i].price)+' </p>'+
                    '<P id="">'+this.slotList[i].stock+'/11</p>'+
                    '</div>'+
                    ' </div>');
                }

             }).catch((e)  => {

            })
       }else{
        this.$router.replace({name: 'Vending'})

       }
    },
    async getDataVending(){
       if (this.$route.params.id) {
      this.Api.get(`/vending/${this.$route.params.id}`).then(res =>{
        this.vending = res.data
        this.formVending = res.data
      })
      .catch((e) =>{

      })
      }
    },
     async getDataCount(){
      if (this.$route.params.id) {
      this.Api.get(`/transaction/countByVending/${this.$route.params.id}`).then(res =>{
        this.count = res.data
      })
      .catch((e) =>{

      })
      }
    },
    getDataMerchant(){
      this.merchantConfig = {
                ajax: {
                    url: env.api.base + '/merchant?sort=id',
                    data: params => {
                        var filters = [
                            ["name", "like", params.term]
                        ]
                        var query = {
                            sort: "name",
                            filters: params.term ? JSON.stringify(filters) : "",
                            page: params.page ? params.page : 1
                        }

                        return query
                    },
                    processResults: (data, params) => {
                        data.content.forEach(item => {
                            item.text = item.name
                        })
                        return {
                            results: data.content,
                            pagination: {
                                more: data.last == false
                            }
                        }
                    }
                }
            }
    },
    signalChange(){
          this.Api.get(`/product/${this.formSlot.product.id}`).then(res => {
                this.formSlot.product = res.data
            }).catch((e)  => {
                console.log(e)
            })
    },
    clearProduct(){
          this.formSlot.product.id=null
          this.formSlot.product.image=null
          this.formSlot.product.name=null
          this.formSlot.product.sku=null
    },
    showModal() {
          //   this.form.id=null;
          //   this.form.name=null;
          //   this.form.sku=null;
          //   this.form.image=null;
          $("#exampleModal").modal("show");
    },
    updateVending() {
            this.loading = true
            this.Api.put(`/vending/${this.formVending.id}`, this.formVending).then(res => {
              this.loading = false
              //this.initData();
              $('#exampleModal').modal('hide');
            }).catch((e)  => {
              this.loading = false
              this.error = e.response.data.message
              this.text_validation = "Submit Failed";    
            })
        },

    formatPrice(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    btnAddUpdate(value){
      var singleValues = $( "#id"+value+"" ).val();
      $("#slotModal").modal("show");
      if(singleValues == 0){
        this.id=null
        this.formSlot.product.id=null
        this.formSlot ={
          id: null,
          name:value,
          product:{
            id: null
          },
          vending:{
            id: null
          },
          price:0,
          stock:0
      }
      }else{
        this.id=singleValues
        this.Api.get('/slot/'+singleValues).then(res => {
          this.formSlot.id= res.data.id
          this.formSlot.name=res.data.name
          this.formSlot.product.id=res.data.product.id
          this.formSlot.product.image=res.data.product.image
          this.formSlot.product.name=res.data.product.name
          this.formSlot.product.sku=res.data.product.sku
          this.formSlot.vending.id=res.data.vending.id
          this.formSlot.price=res.data.price
          this.formSlot.stock=res.data.stock


        }).catch((e)  => {

        })

      }
    },
    save() {
          if (this.$route.params.id) {
            this.formSlot.vending.id = this.$route.params.id
            console.log(this.id)
            if(this.id != null){
              this.Api.put(`/slot/${this.id}`, this.formSlot).then(res => {
                  this.loading = false
                   this.getData()
                  $('#slotModal').modal('hide');
              }).catch((e)  => {
                  this.loading = false
                  this.error = e.response.data.message
                  this.text_validation = "Submit Failed";
                  $('#slotModal').modal('hide');
              })
            }else{
              this.Api.post('/slot', this.formSlot).then(res => {
                  this.loading = false
                  this.getData()
                  $('#slotModal').modal('hide');
              }).catch((e)  => {
                  this.loading = false
                  this.error = e.response.data.message
                  this.text_validation = "Submit Failed";
              })
            }
           
          }else{
            this.$router.replace({name: 'Vending'})
          }
        },
    back(){
      this.$router.replace({name: 'Vending'})
    }
    
  }
}

</script>
<style>
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 8px;
        width: 360px;
        height: 190px;
        left: 64px;
        top: 132px;
        background: #FFFFFF;
        border-radius: 12px; 
    }
    .label1{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #0A0A0A;
    }
    .label2{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #404040;
    }
    .label3{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #404040;
    }
    .label4{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #32434A;

    }
    .balel5{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
    }
    .borderDiv{
      border: 1px solid #C2C2C2;
    }
    .notActive{
        color : red;
    }
</style>